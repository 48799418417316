import "./App.scss";
import { SingleCigarPage } from "./components/SingleCigarPage/SingleCigarPage";

export default function App() {
  return (
    <div className="App__background">
      <SingleCigarPage />
    </div>
  );
}
