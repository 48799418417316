// import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import CigarImage from "../../static/images/best-cigar-sixty-by-rocky-patel-14-of-15.jpg";
import "./SingleCigarPage.scss";

const cigar = {
  brand: "Rocky Patel",
  cigarName: "Rocky Patel Sixty",
  strength: "Full-Body",
  details: {
    country: "Honduras",
    shape: "Corona",
    binder: "Broadleaf",
    color: "Moduro",
    thicknes: "70",
    length: "5",
  },
  backgroundImage: CigarImage,
};

export const SingleCigarPage = () => {
  const { details } = cigar || {};
  return (
    <div className="background">
      <img
        className="h-60 w-full object-cover lg:h-60"
        src={cigar.backgroundImage}
        alt="cigar"
      />
      {/** Body */}
      <div className="mx-auto max-w-5xl px-4">
        <div className="-mt-5 sm:-mt-14  sm:items-end ">
          {/** Heading Pill */}
          <div className="flex">
            <div className="cigar-page__pill h-9 w-36 rounded-xl  drop-shadow-md">
              <p>{cigar.strength}</p>
            </div>
          </div>
          {/** Body */}
          <div className="mt-6  min-w-0 flex-1 items-center justify-end">
            {/** Brand Name*/}
            <div className="mt-6 min-w-0 flex-1">
              <h2 className="cigar-page__brand">{cigar.brand}</h2>
            </div>
            {/** Cigar Name */}
            <div className=" min-w-0 flex-1">
              <h1 className="cigar-page__cigar-name">{cigar.cigarName}</h1>
            </div>
            {/** Details */}
            <div className="mt-6 flex flex-row flex-row space-x-4 space-y-0">
              <div className="flex-1 w-32 heading__details">
                <p>
                  <span>Country</span>
                  {details.country}
                </p>
                <p>
                  <span>Shape</span>
                  {details.shape}
                </p>
                <p>
                  <span>Binder</span>
                  {details.binder}
                </p>
              </div>
              <div className="flex-1 w-32 cigar-page__details">
                <p>
                  <span>Color</span>
                  {details.color}
                </p>
                <p>
                  <span>Thicknes</span>
                  {details.thicknes}
                </p>
                <p>
                  <span>Length</span>
                  {details.length}
                </p>
              </div>
            </div>
            {/** Description */}

            <div className="mt-6 cigar-page__large-text">
              <p>
                The petite 5x44 Henry Clay War Hawk Corona goes against Henry
                Clay tradition by subbing out the typical Connecticut Broadleaf
                wrapper for an Ecuadorian Connecticut Shade wrapper. The
                Connecticut Broadleaf is instead used as the binder for this
                blend, which has aged Honduran long fillers bunched up within.
              </p>
            </div>

            {/** How Smokes */}
            <div className="mt-6 cigar-page__section-title mb-2">
              How Smokes
            </div>
            <div className="flex flex-row flex-wrap">
              <div className="mx-0 flex-none cigar-page__box mr-4 ">
                <span>Flavor</span>Ground
              </div>
              <div className="flex-none  cigar-page__box">
                <span>Strength</span>Full-body
              </div>
              <div className="flex-none cigar-page__box mr-4 mt-4">
                <span>Time</span>45-60 minutes
              </div>
              <div className="flex-none  cigar-page__box mt-4">
                <span>Strength</span>Full-body
              </div>
            </div>
            <div className="cigar-page__video mt-6">
              <iframe
                src="https://www.youtube.com/embed/Y1f--lDz1Us?si=t3zlOjhf1BnW_Zdq"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
